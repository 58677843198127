import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiPhoneCall } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import Logo from "../../elements/logo/Logo";

import "./popup.scss"; // Include CSS for sliding and popup styling
import PricingTwo from "../pricing/PricingTwo";

const PopupForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Automatically open the popup after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 8000); // 2 seconds delay

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  // Function to handle "Book Now" button click
  const handleBookNow = () => {
    setShowForm(true); // Display the Google Form
  };

  const closePopup = () => {
    setIsOpen(false);
    setShowForm(false); // Reset form display when closing
  };

  return (
    <>
      {isOpen && (
        <div className={`popup-overlay ${isOpen ? "slide-in" : "slide-out"}`}>
          <div className="popup-content">
            <button className="close-popup-btn" onClick={closePopup}>
              &times;
            </button>
            {!showForm ? (
              <>
                <div style={{ paddingBottom:"20px" }}>
                  <Logo
                    image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                    image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                  />
                </div>

                <h3>Make Your Event Unforgettable with INZPIRO!</h3>
                <p>
                  Looking for seamless event planning? We offer custom themes,
                  catering, stunning decor, and pro photography to make your
                  special day unforgettable!
                </p>

                <button
                  className="book-now-btn"
                  onClick={() => {
                    window.open(
                      "https://wa.me/919445515129?text=Hi%20INZPIRO,%20I%20would%20like%20to%20get%20a%20free%20quote%20for%20an%20event!",
                      "_blank"
                    );
                  }}
                >
                  Get a Free Quote Now
                </button>
                {/* <button
                  className="book-now-btn"
                  onClick={() => {
                    <PricingTwo></PricingTwo>;
                  }}
                >
                  Get a Free Quote Now
                </button> */}
              </>
            ) : (
              <>
                <h2>Fill Out the Form</h2>
                <a href="https://wa.me/919445515129?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services">
                  <FaWhatsapp
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "white",
                      paddingRight: "10px",
                    }}
                  ></FaWhatsapp>
                </a>

                <iframe
                  width="100%"
                  height="500"
                  loading="lazy"
                  src="https://portal.inzpiro.in/forms/wtl/1d1362e555d00322dbd084cd8cc27935"
                  frameborder="0"
                  sandbox="allow-top-navigation allow-forms allow-scripts allow-same-origin allow-popups"
                  allowfullscreen
                  allowTransparency
                >
                  <p>Loading...</p>
                </iframe>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupForm;
