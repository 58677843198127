import React from "react";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterOne from "../common/footer/FooterOne";
import Slider from "react-slick";
import Copyright from "../common/footer/Copyright";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import VideoItem from "../elements/video/VideoItem";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import { BannerActivation } from "../utils/script";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import GalleryOne from "../elements/gallery/GalleryOne";
import Elements from "../elements/gallery/Gallery";
import FooterTwo from "../common/footer/FooterTwo";
import PricingTwo from "../elements/pricing/PricingTwo";
import PopupForm from "../elements/popup/popup";
import { Popover, PopoverTitle } from "react-bootstrap";
var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
  {
    image: "/images/bg/bg-image-5.jpg",
    title: "Elevate Your Events" + "<br>" + "INZPIRO",
    // description: "Your Partner in Memorable Moments"
  },
  {
    image: "/images/bg/bg-image-6.jpg",
    title: "INZPIRO: Where Dreams Unfold",
    // description: "We help our clients succeed by creating brand identities, digital experiences, and print materials."
  },
  {
    image: "/images/bg/bg-image-6.jpg",
    title: "Creating Magic: INZPIRO Events",
    //description: "We help our clients succeed by creating brand identities, digital experiences, and print materials."
  },
  {
    image: "/images/bg/bg-image-1.jpg",
    title: "Creating Magic: INZPIRO Events",
    //description: "We help our clients succeed by creating brand identities, digital experiences, and print materials."
  },
  {
    image: "/images/bg/bg-image-1.jpg",
    title: "INZPIRO: Where Dreams Unfold",
    //description: "We help our clients succeed by creating brand identities, digital experiences, and print materials."
  }
];

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: ["https://www.youtube.com/watch?v=4ktDYalHd3k&t=7s"],
  },
];

const HomeDefault = () => {
  return (
    <>
      <PopupForm />
      <SEO title="INZPIRO" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
          autoplay={true}
          autoplaySpeed={4000}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        {/* <div className="button-group mt--30">
                                                    <a className="btn-default" target="_blank" href="">PURCHASE NOWW</a>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container">
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        {/* Start Portfolio Area  */}
        <div className="rwt-portfolio-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our work"
                  title="Portfolio !"
                  description="We actually capture the real love of your wedding."
                />
              </div>
            </div>
            <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
          </div>
        </div>

        {/* End Portfolio Area  */}

        <Separator />
        {/* Start Timeline Area  */}
        {/* <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Working Process"
                                        title = "Business Working Process."
                                        description = "We provide company and finance service for <br /> startups and company business."
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* End Timeline Area  */}
        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Pricing"
                  title="Our Packages"
                  description=""
                />
              </div>
            </div>
            <PricingTwo />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Video Area  */}
        <div className="rwt-video-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              {PopupData.map((item) => (
                <div className="col-lg-12" key={item.id}>
                  <VideoItem galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Video Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="TESTIMONIALS"
                  title="Our Family Feedback."
                  description="We work with great diligence to convert any dream event into a reality."
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />
        {/* <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>  */}

        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
